<template lang="">
<div>
    <div class="d-flex justify-center ma-3" style="color:white;">
        <h2 >กิจกรรมพัฒนาสมอง</h2>
    </div>
    <!-- <v-img style="display: block;margin-left: auto;margin-right: auto;" src="@/assets/img/checkmong/knowledge/1img_page-0001.jpg" width="100%"></v-img>
    <br>
    <v-img style="display: block;margin-left: auto;margin-right: auto;" src="@/assets/img/checkmong/knowledge/2img_page-0001.jpg" width="100%"></v-img>
    <br>
    <v-img style="display: block;margin-left: auto;margin-right: auto;" src="@/assets/img/checkmong/knowledge/3img_page-0001.jpg" width="100%"></v-img>
    <br>
    <v-img style="display: block;margin-left: auto;margin-right: auto;" src="@/assets/img/checkmong/knowledge/4img_page-0001.jpg" width="100%"></v-img>
    <br>
    <v-img style="display: block;margin-left: auto;margin-right: auto;" src="@/assets/img/checkmong/knowledge/5img_page-0001.jpg" width="100%"></v-img>
    <br>
    <v-img style="display: block;margin-left: auto;margin-right: auto;" src="@/assets/img/checkmong/knowledge/6img_page-0001.jpg" width="100%"></v-img>
    <br>
    <v-img style="display: block;margin-left: auto;margin-right: auto;" src="@/assets/img/checkmong/knowledge/7img_page-0001.jpg" width="100%"></v-img>
    <br> -->
    
    <v-img style="display: block;margin-left: auto;margin-right: auto;" src="@/assets/img/checkmong/knowledge/8img_page-0001.jpg" width="100%"></v-img>
    <br>
    <div class="d-flex justify-end" >
        <v-btn color="white" elevation="0" style="color:#AE1B77 !important;" class="mr-5" @click="$router.push('/select_brain_library')" >กลับ</v-btn>
    </div>
    <br>
    <!-- <br>

    
    
    <v-container >
        <v-layout align-center justify-center>
            <h3>ศึกษาข้อมูลเพิ่มเติมได้ที่&nbsp;</h3>
            <br>
            <br>
            <h3><a href="https://hhcthailand.com/category/health/brain/" target="_blank">hhcthailand.com</a></h3>
        </v-layout>
      </v-container>
    <br>
    <br> -->

</div>
</template>

<script>
export default {
    
    data() {
        return {

        }
    },
    methods: {

    },
    
    

}
</script>

<style lang="">

</style>
